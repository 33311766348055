<template>
  <div
    :class="['zcard', { 'zcard-borderless': noBorder }]"
    @click="emit('click')"
  >
    <template v-if="noBody">
      <slot />
    </template>
    <ZCardBody
      v-else
      :title="title"
    >
      <slot />
    </ZCardBody>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  noBody?: boolean
  title?: string
  noBorder?: boolean
}>(), {
  noBody: false,
  title: '',
  noBorder: false,
})

const emit = defineEmits<{
  click: []
}>()
</script>
